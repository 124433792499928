import React, { useContext, useEffect } from "react";
import CheckoutContext from "./useCheckout";
import { T } from "../../traks";
import { url_lang } from "../../languages";

const IframeCheckout = ({
	onSuccess,
	onError = (e) => console.log("error", e),
}) => {
	const { completed, error, loading, setLoading, checkoutUrl, checkoutType } =
		useContext(CheckoutContext);
	useEffect(() => {
		if (completed) {
			onSuccess();
		} else if (error) {
			console.log("Checkout error:", error);
			setLoading(false);
			onError();
		}
	}, [completed, onSuccess, onError, error, setLoading]);

	if (!checkoutType) return <SelectCheckoutType />;
	return (
		<div className="w-100 h-100" style={{ minHeight: 300 }}>
			{loading && (
				<div className="d-flex justify-content-center align-items-center w-100">
					<div
						className="spinner-border"
						role="status"
						style={{ width: "3rem", height: "3rem" }}
					/>
				</div>
			)}
			{checkoutUrl ? 
			<iframe
				title="checkout"
				src={checkoutUrl}
				style={{ width: "100%", height: "400px" }}
				className={loading ? "invisible" : ""}
			/>:null}
			{}
		</div>
	);
};

const SelectCheckoutType = () => {
	const { setCheckoutType } = useContext(CheckoutContext);
	const {lang} = url_lang();
	return (
		<div
			className="w-100 h-100 d-flex flex-column gap-4 justify-content-center align-items-center"
			style={{ minHeight: 300 }}
		>
			<div className="w-50 p-3">
				<button
					className="btn btn_grey d-flex flex-row align-items-center w-100"
					onClick={() => setCheckoutType("iframe")}
				>
					<img
						src="https://static.zevio.com/gfx/ico_generic-credit-card-0.svg"
						alt="creditcardicon"
						className="h-100 w-25"
					/>
					<span className="w-75">
						<T>Pay with credit card</T>
					</span>
				</button>
			</div>
			{ lang === "da" && 
			<button className="btn w-50">
				<img
					onClick={() => setCheckoutType("mobilepay")}
					src="https://cdn.mobilepay.dk/res-website/img/buttons/standard/mpblue/medium.svg"
					className="w-100"
					alt="mobilepaybutton"
				/>
			</button>
			}
		</div>
	);
};

export default IframeCheckout;

/**
 *
 */
